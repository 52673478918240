import { AcmErrorCode } from '@canalplus/types-acm';
import { AcmError } from './AcmError';

export class AcmLockedSafetyCodeError extends AcmError {
  constructor() {
    super(
      AcmErrorCode['Code_401.2'],
      'Too many code errors in a short time',
      401,
    );

    Object.setPrototypeOf(this, AcmLockedSafetyCodeError.prototype);

    this.name = 'AcmLockedSafetyCodeError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AcmLockedSafetyCodeError);
    }
  }
}
