import { ModalV2 } from '@canalplus/mycanal-sharedcomponent';
import { SafetyCodeContext } from '@canalplus/types-acm';
import { Binder, KEY_BACK, useKeyCatcher } from '@dce-front/one-navigation';
import { Type } from '@dce-front/onewebapp-utils';
import classNames from 'classnames';
import type { JSX } from 'react';
import { memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { clearTimeout, setTimeout } from 'timers';
import { TemplateTypes } from '../../../constants/templateTypes';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { LAYER_ADULT_KEYBOARD } from '../../../helpers/oneNavigation/layers';
import { KEYBOARD_MIDDLEWARE } from '../../../helpers/oneNavigation/middleware';
import { useTranslation } from '../../../lang';
import {
  hasReadDisclaimer,
  updateAdultBrowsing,
  updateAdultError,
} from '../../../store/slices/adult';
import { adultErrorSelector } from '../../../store/slices/adult-selectors';
import {
  updatePurchaseCodeBrowsing,
  updatePurchaseCodeError,
} from '../../../store/slices/purchaseCode';
import { purchaseCodeErrorSelector } from '../../../store/slices/purchaseCode-selectors';
import Button from '../../Button/Button';
import {
  VirtualKeyboard,
  virtualKeyboardContext,
} from '../../VirtualKeyboard/VirtualKeyboard';
import stylesQrCode from '../SafetyCodeTV/SafetyCodeInputQrCodeTV/SafetyCodeInputQrCodeTV.css';
import { useSubmitSafetyCode } from '../useSubmitSafetyCode';
import SafetyCodeInputFieldTV from './SafetyCodeInputFieldTV/SafetyCodeInputFieldTV';
import SafetyCodeInputQrCodeTV from './SafetyCodeInputQrCodeTV/SafetyCodeInputQrCodeTV';
import styles from './SafetyCodeInputTV.css';
import type { SafetyCodeInputTVProps } from './types';

const CODE_CHECK_DURATION = 500; // Set a timeout so the user sees their last input
const INPUT_CODE_LENGTH = 4;

function SafetyCodeInputTV({
  safetyCodeContext,
  purchaseId,
  onSuccess,
}: SafetyCodeInputTVProps): JSX.Element {
  const [isModalOpen, setOpenModal] = useState(false);
  const [isKeyboardDisabled, setDisableKeyboard] = useState(false);
  const [isLocked, toggleIsLocked] = useState(false);

  const isParentalCodeContext =
    safetyCodeContext === SafetyCodeContext.Parental;
  const isPurchaseCodeContext =
    safetyCodeContext === SafetyCodeContext.Purchase;

  const {
    state: { inputValue },
    setInputValue: setVirtualKeyBoardInputValue,
  } = useContext(virtualKeyboardContext);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const adultError = useSelector(adultErrorSelector);
  const purchaseCodeError = useSelector(purchaseCodeErrorSelector);

  useKeyCatcher(KEY_BACK, () => setOpenModal(false));

  /**
   * Input value from **VirtualKeyboard** is a `string[]`\
   * We are transforming it into a simple string
   */
  const code = inputValue.join('');

  const submitSafetyCode = useSubmitSafetyCode({
    purchaseId,
    safetyCodeContext,
    code,
    onSuccessCode: (validationToken) => {
      // TODO: Refacto from REDUX as mush as possible
      if (isParentalCodeContext) {
        dispatch(updateAdultBrowsing());
        dispatch(hasReadDisclaimer());
      }
      if (isPurchaseCodeContext) {
        dispatch(updatePurchaseCodeBrowsing());
      }
      onSuccess?.(validationToken);
    },
    onWrongCode: () => {
      if (isParentalCodeContext) {
        dispatch(updateAdultError(t('SafetyDisclaimer.wrongCode')));
      }
      if (isPurchaseCodeContext) {
        dispatch(updatePurchaseCodeError(t('SafetyDisclaimer.wrongCode')));
      }
      setVirtualKeyBoardInputValue('');
    },
    onLockedCode: () => {
      toggleIsLocked(true);
      setVirtualKeyBoardInputValue('');
    },
  });

  useEffect(() => {
    if (code.length > 0) {
      if (isParentalCodeContext) {
        dispatch(updateAdultError(''));
      }
      if (isPurchaseCodeContext) {
        dispatch(updatePurchaseCodeError(''));
      }
    }

    if (code.length === INPUT_CODE_LENGTH) {
      setDisableKeyboard(true);

      const timeout = setTimeout(async () => {
        await submitSafetyCode();

        setDisableKeyboard(false);
      }, CODE_CHECK_DURATION);

      return () => {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  const safetyCodeCodeInputFieldTitle = isLocked
    ? t(`SafetyDisclaimer.${safetyCodeContext}.lockedTitle`)
    : t(`SafetyDisclaimer.${safetyCodeContext}.safetyCodeTitle`);

  const safetyCodeInputFieldSubtitle = isLocked
    ? t(`SafetyDisclaimer.${safetyCodeContext}.lockedText`)
    : t(`SafetyDisclaimer.${safetyCodeContext}.safetyCodeInstruction`);

  return (
    <div
      className={classNames(styles.SafetyCodeInput, {
        [styles['SafetyCodeInput--locked']!]: isLocked,
      })}
    >
      <div className={styles.SafetyCodeInput__inputAndTitleContainer}>
        <div className={styles.SafetyCodeInput__titleContainer}>
          <h2 className={styles.SafetyCodeInput__title}>
            {safetyCodeCodeInputFieldTitle}
          </h2>
          <p className={styles.SafetyCodeInput__subtitle}>
            {safetyCodeInputFieldSubtitle}
          </p>
        </div>
        {!isLocked ? (
          <>
            {/* Code Input */}
            <SafetyCodeInputFieldTV
              filledInputs={code.length}
              inputLength={INPUT_CODE_LENGTH}
              error={adultError || purchaseCodeError}
            />
            {/* QRCode - Safety code forgotten */}
            <Binder layer={LAYER_ADULT_KEYBOARD}>
              <Button
                className={classNames(
                  styles.SafetyCodeInput__button,
                  'SafetyCodeForgottenButtonFocus',
                )}
                color={
                  $_BUILD_RENDERMODE_CSR
                    ? TemplateTypes.TV
                    : TemplateTypes.PRIMARY
                }
                text={t(`SafetyDisclaimer.${safetyCodeContext}.forgotten`)}
                handler={() => setOpenModal(true)}
              />
            </Binder>
            {isModalOpen && (
              <ModalV2
                classNameBody={stylesQrCode.CodeInputQrCodeTV__modal}
                isTvDevice={$_BUILD_RENDERMODE_CSR}
                onClose={() => setOpenModal(false)}
                hideCloseButton={$_BUILD_RENDERMODE_CSR}
                size="fullscreen"
              >
                <SafetyCodeInputQrCodeTV
                  safetyCodeContext={safetyCodeContext}
                />
              </ModalV2>
            )}
            {/* Virtual keyboard */}
            <Binder
              layer={LAYER_ADULT_KEYBOARD}
              middleware={KEYBOARD_MIDDLEWARE}
              forceFocusOnMount
            >
              <VirtualKeyboard
                isDisabled={isKeyboardDisabled}
                type={Type.Number}
                maxInputValue={INPUT_CODE_LENGTH}
                layout="big"
              />
            </Binder>
          </>
        ) : (
          <Binder layer={LAYER_ADULT_KEYBOARD} forceFocusOnMount>
            <Button
              text={t(`SafetyDisclaimer.close`)}
              handler={() => toggleIsLocked(false)}
              className={styles.SafetyCodeInput__button}
              isV5Style
            />
          </Binder>
        )}
      </div>
    </div>
  );
}

export default memo(SafetyCodeInputTV);
