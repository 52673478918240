/**
 * URL Helper
 * --------------------
 *
 * Add a **protocol** to an url if it doesn't have one.
 *
 * @param url The **url** to parse
 * @param protocol The **protocol** to be added
 * @returns Returns url with the protocol added
 *
 * @category Url
 * @examples
 * getUrlWithProtocol('url.com')
 * // => 'https://url.com'
 * getUrlWithProtocol('url.com', 'http')
 * // => 'http://url.com'
 * getUrlWithProtocol('url.com', 'https')
 * // => 'https://url.com'
 *
 */
export const getUrlWithProtocol = (
  url: string,
  protocol: 'http' | 'https' = 'https',
): string => {
  if (url.includes('://')) {
    return url;
  }
  return `${protocol}://${url}`;
};
